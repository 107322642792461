const mutations = {
  setError(state, payload) {
    state.error = payload;
  },
    setUser(state, payload) {
        state.user = payload;
      },

};

export default mutations;