<template>

    <nav class="navbar navbar-expand-lg navbar-light">
            <router-link to="/entrance">
            <svg width="120" height="50" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                viewBox="260 0 600 600">
                   

                <g id="logo">
                    <path class="logo_letter logo_letter_hover"
                        d="M1041.63,778H977.37A23.37,23.37,0,0,1,954,754.63V452.37A23.37,23.37,0,0,0,930.63,429H835.45a4.54,4.54,0,0,1-4.36-5.78c3.41-11.88,13.13-24.09,15.62-47.72,2.13-20.14,2.49-38.93-.38-47.56a4.51,4.51,0,0,1,4.29-5.94h332A23.37,23.37,0,0,1,1206,345.37v60.26A23.37,23.37,0,0,1,1182.63,429h-94.26A23.37,23.37,0,0,0,1065,452.37V754.63A23.37,23.37,0,0,1,1041.63,778Z"
                        transform="translate(-83 -246)" />
                    <path class="logo_letter"
                        d="M293,646.42c17.46,17.45,41.83,49.8,45.59,101.57,1.2,16.5,15.67,30,32.22,30h41.56c16.8,0,26.43-8.15,24.89-32.11-1.75-27.15-13-60.84-13-60.84-7.48-37.43-21.21-69.87-71.11-108.54-1.75-1.36,59.88-23.71,69.86-82.35,8.51-50-3.85-84.23-6.23-89.83-22.68-53.18-75.22-87.75-142.23-87.33H116.21A33.21,33.21,0,0,0,83,350.21V753.3A25.76,25.76,0,0,0,109,779l52.38-.32A25.77,25.77,0,0,0,187,753V616.49a7.49,7.49,0,0,1,7.49-7.49h15A155.7,155.7,0,0,1,293,646.42ZM257.92,520H195.49a7.49,7.49,0,0,1-7.49-7.49V397.6a7.5,7.5,0,0,1,7.42-7.49c20.84-.22,41.68-.66,62.5.05,51.71,1.75,68.71,26.75,68.71,58.75C326.63,495.13,312.85,520,257.92,520Z"
                        transform="translate(-83 -246)" />
                    <path class="logo_letter"
                        d="M470,317h65a19.94,19.94,0,0,1,17.47,10.35L677.58,548A60.35,60.35,0,0,1,685,577v182A19.94,19.94,0,0,1,665.06,779H600.94A19.94,19.94,0,0,1,581,759.06V587.21a60.32,60.32,0,0,0-7.45-29.05l-121-211.61A19.94,19.94,0,0,1,470,317Z"
                        transform="translate(-83 -246)" />
                    <image id="Layer_0_copy" data-name="Layer 0 copy" class="cls-2" width="401" height="525"
                        transform="matrix(0.49, -0.13, 0.13, 0.46, 534, 53.9)" xlink:href="@/assets/leaf_nostem.png" />
                </g>
                <router-link to="/" xmlns="http://www.w3.org/2000/svg" id="anchor" xlink:href="../views/index.html"
                    xmlns:xlink="http://www.w3.org/1999/xlink" target="_top">
                    <!-- <rect id="svg_overlay" x="-160" y="0" width="240%" height="100%" fill-opacity="0.5" /> -->
                </router-link>
            </svg>
        <!-- </a> -->
            </router-link>
        <!-- <router-link to="/" class="navbar-brand" href="#">RYT</router-link> -->
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav mr-auto">
                <li class="nav-item">
                    <router-link to="/companies" class="nav-link">Database</router-link>
                </li>
                <li class="nav-item">
                    <router-link to="/add" class="nav-link">Add</router-link>
                </li>
                                <li class="nav-item">
                    <router-link to="/dashboard" class="nav-link">Dashboard</router-link>
                </li>
            </ul>
    <div v-if="isUserAuth" class="mr-4">
      Logged in {{ getUser.email }}
    </div>
            <template v-if="!isUserAuth">
                <router-link v-for="item of navItems" :key="item.name" :to="item.to" class="btn btn-info mx-1">
                    <span class="icon">
                        <i :class="`fas fa-${item.icon}`"></i>
                    </span>
                    <strong>{{ item.name }}</strong>
                </router-link>
            </template>
            <button v-if="isUserAuth" @click="signOut" type="button" class="btn btn-danger">Logout</button>
        </div>
    </nav>
</template>

<script>
    import {
        mapGetters,
        mapActions
    } from "vuex";
    export default {
        data() {
            return {
                navItems: [{
                        name: "Login",
                        to: "/login",
                        icon: "sign-in-alt"
                    },
                    {
                        name: "Register",
                        to: "/register",
                        icon: "user-plus"
                    }
                ]
            };
        },
        computed: {
            ...mapGetters(["getUser", "isUserAuth"])
        },
        methods: {
            ...mapActions(["signOutAction"]),

            signOut() {
                //@TODO: Write signout functionality later
                this.signOutAction();
            }
        }
    };
</script>

<style lang="scss" scoped>
.navbar {
    position: fixed;
    width: 100vw;
    background-color: #dce7f3;
}
</style>