import firebase from "firebase/app";
import "firebase/database";
import "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyC4o1WIxPRFpZUSl1lyWkx3TDa1DRyYP6c",
  authDomain: "rytapp-auth.firebaseapp.com",
  databaseURL: "https://rytapp-auth-default-rtdb.europe-west1.firebasedatabase.app/",
  projectId: "rytapp-auth",
  storageBucket: "rytapp-auth.appspot.com",
  messagingSenderId: "419279637686",
  appId: "1:419279637686:web:5e5f0c4c3b09304635ea17",
  measurementId: "G-1WJNTG02DM"
}

firebase.initializeApp(firebaseConfig);

firebase.getCurrentUser = () => {
  return new Promise((resolve, reject) => {
      const unsubscribe = firebase.auth().onAuthStateChanged(user => {
          unsubscribe();
          resolve(user);
      }, reject);
  })
};

export default firebase.database();
