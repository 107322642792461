import { createWebHistory, createRouter } from "vue-router";
import store from "./store";

const routes =  [
  {
    path: "/",
    alias: "/companies",
    name: "companies",
    component: () => import("./components/CompaniesList"),
        meta: {
      requiresAuth: true
    }
  },
  {
    path: "/add",
    name: "add",
    component: () => import("./components/AddCompany"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/login",
    name: "login",
    component: () => import("./components/Login")
  },
  {
    path: "/register",
    name: "Register",
    component: () => import("./components/Register")
  },
  {
    path: "/entrance",
    name: "entrance",
    component: () => import("./components/Entrance"),
    meta: {
      requiresAuth: false
    }
  },  {
    path: "/dashboard",
    name: "dashboard",
    component: () => import("./components/Dashboard"),
    meta: {
      requiresAuth: true
    }
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  if (requiresAuth && !store.state.user){
    next('login');
  }else{
    next();
  }
});

export default router;