import firebase from 'firebase/app';
import router from '../router';

const actions = {
    signUpAction({ commit }, payload) {
        console.log("signUpAction commit:", commit)
        firebase
          .auth()
          .createUserWithEmailAndPassword(payload.email, payload.password)
          .catch(error => {
            // console.log("signUpAction error: ", error)
            commit("setError", error.message);
          });
      },
      async signInAction({ commit }, payload) {
        return firebase
          .auth()
          .signInWithEmailAndPassword(payload.email, payload.password)
          .catch(error => {
            commit("setError", error.message);
          });
      },
      signOutAction({commit}) {
        firebase
          .auth()
          .signOut()
          .then(() => {
            commit("setUser", null);
            router.replace('login')
          })
          .catch(error => {
            commit("setError", error.message);
          });
      },
      authAction({commit}) {
        firebase.auth().onAuthStateChanged(user => {
          if (user) {
            commit("setUser", user);
          } else {
            commit("setUser", null);
          }
        });
      }
};

export default actions;