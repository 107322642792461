<template>
  <div id="app">
    <div>
    <navbar />
    </div>










    <!-- <div class="container mt-3"> -->
      <!-- <h2>Finally got the fucking database to work</h2> -->
      <div class="view-container">
      <router-view />
      </div>
    <!-- </div> -->
  </div>
</template>

<script>
import Navbar from "./components/Navbar";
// import Navbar from './components/Navbar.vue';
import {mapGetters, mapActions} from 'vuex';

export default {
  name: "app",
  components: {
    Navbar
  },
  methods: {
  ...mapActions(["authAction"])
},
  computed: {
  ...mapGetters(['getUser', 'isUserAuth'])
},
mounted() {
    this.authAction();

}
};
</script>

<style lang="scss">
.view-container{
  height: 100%;
  padding-top: 60px;
}
html {
  height: 100vh;
}
#app {
  height: 100vh;
}
body {
  margin: 0;
}
html {
  margin: 0;
  padding: 0;
  border-radius: 100;
}
#app {
  width: 100%;
  background-color: #f5f5f5
}
@import url('https://fonts.googleapis.com/css2?family=Baloo+Bhaijaan+2&family=Lora:wght@400;500;600;700&display=swap');* {
    text-align: left;
    font-family: 'Lora', serif;
    }




  .card_heading {
    color: rgb(73, 77, 73);
    font-size: 2rem;
    font-weight: 600;
  }


.va-card__title{
  padding: 0.75rem !important;
  padding-right: 0.5rem !important;
}

  .card_list {
    padding-left: 0.5rem;
    margin: 0;

    .text.item {
      font-size: 1.1rem;
    }
  }

  .row_box {
    margin-top: 2rem;
  }

  .row_box_1 {
    margin-top: 1.5rem;
  }

  .card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: transparent !important;
    border-bottom: none !important;
  }

  // .card-header_1 {
  //   display: flex;
  //   justify-content: space-between;
  //   align-items: center;
  // }

  .text {
    font-size: 14px;
  }



.card {
    // margin-left: 10px !important;
    // margin-right: 10px !important;
  padding: 0.5rem;

    // background: linear-gradient(0deg, #cfdddf, #f6ffff);
    background: linear-gradient(0deg, #e2f1f3, #e7f9ff);
    box-shadow: 5px 5px 10px #d1dfe2,
      -5px -5px 10px #fbffff !important;

          border-radius: 1rem !important;


    }
.card {
            border-radius: 1rem !important;
    // border: 2px solid rgba(0,0,0,.125) !important;
    border: none !important;
}

  .card:hover {
    transition: all 0.3s ease-in-out;
    box-shadow: 10px 10px 10px #d1dfe2,
      -10px -10px 26px #fbffff !important;
    transform: scale(1.005);
  }

  .card-body {
    border: 2px solid rgba(0,0,0,.125);
    border-radius: 1rem;
    background: linear-gradient(107deg, #e9fff6, #e3fcfb);
    box-shadow: inset 1px 1px 4px #9fa9ab, inset -2px -2px 3px #ffffff, 0px 0px 0px #9fa9ab, 0px 0px 0px #ffffff;
  }

  @media only screen and (max-width: 768px) {
    .el-col {
      padding-top: 0rem;
    }
        .tm-1rem {
    margin-top: 1rem;

}
  .card_heading {
    font-size: 1.5rem;
    font-weight: 600;
  }
    .list_tag {
    height: 1.7rem;
    margin-bottom: 0.5rem;
  }


  
  }


///////////////// GLOBAL STYLE CLASSES ////////////////////
.no_decor {
  text-decoration: none !important;
}
.fullWidth {
  width: 100%;
}
.fullHeight{
  height: 100%;
}
.mx-auto {
 margin-right: auto !important;
 margin-left: auto !important;
}
</style>